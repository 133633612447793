import axios from 'axios';
import { computed, toJS } from 'mobx';
import { model, Model, modelAction, prop, tProp, types } from 'mobx-keystone';
import { Tile as TileLayer } from 'ol/layer';
import XYZ from 'ol/source/XYZ';
import { LayerItem } from 'components/layers/layers.store';
import { TELLURIC_URI } from 'config';
import { getTasksByProjectName } from 'config/helpers'
import { Layer } from 'typings/stores';
import { CaptureFiltersData, Task } from 'typings';
import { getLayers } from 'services';
import { mobxRootContext } from 'contexts';


interface ScenesResponse {
  results: Layer[];
  next?: string;
  previous?: string;
  count: number;
}

@model('geodas/Layers')
export class DashboardStore extends Model({
  layers: prop<any>(),
  metadataModal: tProp(types.boolean, false),
  activeIndex: tProp(types.number, 0),
  showTour: tProp(types.boolean, false),
  products: prop(),
  filters: prop<CaptureFiltersData>(() => {
    return {
      query: '',
      startDate: '',
      endDate: '',
      projects: [],
      taskId: '',
      projectId: ''
    };
  }),
  filtering: prop()
}) {
  limit: number = 15;
  paginationToken?: string = undefined; // This requests uses token-based pagination.
  axiosCancelToken: any;

  @modelAction
  setGeneratedLayers(list: any, replace: boolean) {
    this.layers = replace ? list : [...this.layers, ...list];
  }

  @modelAction
  setLayers(response: ScenesResponse, replace: boolean) {
    this.generateLayers(response.results).then((response: any) => {
      this.setGeneratedLayers(response, replace);
    });
    this.paginationToken = response.next;
  }

  @modelAction
  clearLayers() {
      this.layers = [];
  }

  @computed
  get layersByDate() {
    const layers = this.layers?.slice().sort((a: LayerItem, b: LayerItem) =>
      a.metadata?.timestamp > b.metadata?.timestamp ? -1 : 1
    );
    layers?.forEach((item: LayerItem, k: number) => {
      item.getOLItem()?.setZIndex(layers.length * 100 - (k + 1));
    });
    return layers;
  }

  @modelAction
  showMetadataModal(v: boolean = !this.metadataModal, layer?: LayerItem) {
    if (layer) {
      this.activeIndex = this.layers.indexOf(layer);
    }
    this.metadataModal = v;
  }

  @computed
  get filtersJson() {
    return toJS({
      query: this.filters.query,
      startDate: this.filters.startDate,
      endDate: this.filters.endDate,
      projects: this.filters.projects.map(item => toJS(item)),
      taskId: this.filters.taskId,
      projectId: this.filters.projectId
    });
  }

  @modelAction
  updateFilters(filters: any) {
    this.filters = {
      ...this.filtersJson,
      ...filters
    };
  }

  @modelAction
  setFiltering(v: boolean = !this.filtering) {
    this.filtering = v;
  }

  async fetchDownload(capture: LayerItem, callback?: (r: any) => void) {
    const token = await mobxRootContext.get(this)?.userStore.auth0Client?.getTokenSilently();
    axios
      .get(`${TELLURIC_URI}/scenes/${capture.layerId}/attachments/${capture.getOLItem() ? 'delivery_zip' : 'video_product'}/download/?redirect=false`, {
        headers: { authorizationToken: `Bearer ${token}` }
      })
      .then(response => {
        if (callback) callback(response);
      })
      .catch(data => {
        mobxRootContext.get(this)?.notificationStore.add({ description: 'Error while fetching download URL.' });
        console.error(data);
      });
  }

  async generateLayers(list: Layer[]) {
    const token = await mobxRootContext.get(this)?.userStore.auth0Client?.getTokenSilently();
    return list.map((item, k) => {
      const layer = new LayerItem({
        name: item.metadata.task_name || item.metadata.project_name ? `${item.metadata.project_name} - ${item.metadata.task_id}` : item.scene_id,
        layerId: item.scene_id,
        metadata: item
      });
      if (item.metadata.webmap_raster !== "video") {
        const tile = new TileLayer({
          zIndex: 1,
          visible: false,
          preload: Infinity,
          source: new XYZ({
            tileLoadFunction: function(tile: any, src) {
              const client = new XMLHttpRequest();
              client.responseType = 'arraybuffer';
              client.open('GET', src);
              client.setRequestHeader('authorizationToken', 'Bearer ' + token);
              client.onload = function() {
                const arrayBufferView = new Uint8Array(this.response);
                const blob = new Blob([arrayBufferView], { type: 'image/png' });
                const urlCreator = window.URL || window.webkitURL;
                const imageUrl = urlCreator.createObjectURL(blob);
                tile.getImage().src = imageUrl;
              };
              client.send();
            },
            url: `${TELLURIC_URI}/scenes/${item.scene_id}/rasters/${item.metadata.webmap_raster}/get_tile/?&x={x}&y={y}&z={z}`
          })
        });
        tile.set('id', item.sceneset_id);
        layer.setOLItem(tile);
      }
      return layer;
    });
  }

  async getLayers(
    q: string = '',
    replace: boolean = false,
    filter: boolean = false,
    callback?: (response: any) => void,
    appendPaginationToken?: boolean
  ) {
    if (filter) this.setFiltering(true);

    const opt: any = {
      token: await mobxRootContext.get(this)?.userStore.auth0Client?.getTokenSilently()
    };

    if (q) {
      if (q.split('-').length === 5 || (q.split('-').length === 7 && q.split('--').length === 2)) {
        opt['sceneset_id__startswith'] = q.split('--')[0];
      } else {
        const tasks = mobxRootContext.get(this)?.taskStore.taskNames.filter((task: Task) => task.task_name.toLowerCase().includes(q.toLowerCase()))
        opt['metadata__task_id__in'] = tasks.length > 0 ? tasks.map((task: Task) => task.task_id) : [0]
      }
    }
    if (this.filters.projects.length > 0) {
      const tasksByProject = getTasksByProjectName(this.filters.projects[0].text, mobxRootContext.get(this)?.taskStore.taskNames)
      opt['metadata__task_id__in'] = tasksByProject.map(task => task.task_id)
    }
    if (this.filters.endDate)
      opt['timestamp_before'] = new Date(this.filters.endDate).toISOString();
    if (this.filters.startDate)
      opt['timestamp_after'] = new Date(this.filters.startDate).toISOString();
    if (this.filters.taskId)
      opt['metadata__task_id__icontains'] = Number(this.filters.taskId)
    if (this.filters.projectId)
      opt['metadata__project_id__icontains'] = Number(this.filters.projectId)

    opt["productname__in"] = ["L1 TOA Reflectance", "L1 TOA Reflectance SR", "HSI"]

    getLayers(
      opt,
      response => {
        if (callback) callback(response);
        this.setLayers(response.data, replace);
        if (callback) callback(response);
        if (filter) this.setFiltering(false);
      },
      error => {
        if (!error?.message) {
          mobxRootContext
            .get(this)
            ?.notificationStore.add({ description: 'Error while loading layers.' });
          this.clearLayers();
        }
      },
      this.limit,
      token => {
        if (this.axiosCancelToken) this.axiosCancelToken.cancel('Axios request canceled.');
        this.axiosCancelToken = token;
      },
      appendPaginationToken ? this.paginationToken : null
    );
  }
}
