import axios from 'axios';
import { API_URI } from 'config';

  
export interface ProcessingRequest {
    activity_outcome_id: string;
    product_requested: string;
  }


export const triggerImageryProcessing = async (
    token: string | undefined,
    request: ProcessingRequest,
    onSuccess: (r: any) => void,
    onError: (r: any) => void,
) => {
        
    axios
        .post(`${API_URI}/aleph/processing`, request, {
            headers: { authorizationToken: `Bearer ${token}` }
        })
        .then((response: any) => {
            onSuccess(response);
        })
        .catch((error: any) => {
            onError(error.response ? error.response : error);
        });
};
