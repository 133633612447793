import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  z-index: 10000;
  padding: 7px 15px;
  background: ${(props: any) => props.theme.primary};
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-between;
  .menuWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const MenuBurger = styled.div`
  margin: 5px 10px 0 0;
`;

export const SidebarContainer = styled.aside`
  width: 100%;
  max-width: 320px;
  background-color: ${(props: any) => props.theme.neutral05};
  box-shadow: 3px 3px 18px -9px rgba(180, 180, 180, 1);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 4px 0;
  transform: translateX(-100%);
  transition: transform .3s ease-in-out;
  z-index: 99999999;
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  &.isOpen {
    transform: translateX(0);
  }
`

export const SidebarMenu = styled.div`
  flex: 1 1 auto;
  .menu-item {
    margin: 4px 8px;
  }
`

export const AccordionListItem = styled(Link)`
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral80};
  cursor: pointer;
  ${(props: any) => props.theme.small.styles};
  display: flex;
  margin-left: 45px;
  padding: 10px;
  text-decoration: none;
  &:hover{
    /* background: ${(props: any) => props.theme.neutral10}; */
    /* font-weight: 600; */
  }
`

export const SidebarFooter = styled.div`
  width: 100%;
  flex: 0 0 auto;
  border-top: 1px solid ${(props: any) => props.theme.neutral20};
  padding-top: 4px;
  .menu-item {
    margin: 4px 8px;
  }
  .menu-footer-brand {
    margin: 4px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }

  .menu-powered-by {
    margin: 4px 5px 4px 0;
    padding-top: 5px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
`

export const Container = styled.div`
  width: 90%;
  margin: auto;
`

export const ListItem = styled(Link)`
  align-items: center;
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  ${(props: any) => props.theme.body.styles};
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color .3s ease-in-out;
  &:hover {
    background-color: ${(props: any) => props.theme.neutral10};
  }
  > svg {
    margin-right: 12px;
  }
  .guide_icon path {
    fill: ${(props: any) => props.theme.neutral80};
  }
`;

export const ListItemAction = styled.div`
  align-items: center;
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  ${(props: any) => props.theme.body.styles};
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color .3s ease-in-out;
  &:hover {
    background-color: ${(props: any) => props.theme.neutral10};
  }
  > svg {
    margin-right: 12px;
  }
`;
