import axios from 'axios';
import { TELLURIC_URI } from 'config';

export const getLayers = (
  opt: any,
  onSuccess: (r: any) => void,
  onError: (r: any) => void,
  limit: number,
  cancelCallback?: (t: any) => void,
  paginationToken?: string | null // The API request uses token-based pagination.
) => {
  // Use the provided URL or fall back to the default
  var url = `${TELLURIC_URI}/scenes/search/?limit=${limit}&omit=supplier,satellite_class,satellite_name,rasters,attachments,bands`;
  // If a valid pagination token is provided, append it to the url.
  if (!!paginationToken) {
      url = `${url}&token=${paginationToken}`;
  }
  const t = opt.token;
  delete opt.token;
  opt['metadata__webmap_raster__isnull'] = false;
  opt['omit'] = 'supplier,satellite_class,satellite_name,rasters,attachments,bands'
  const cancelToken = axios.CancelToken.source();
  if (cancelCallback) cancelCallback(cancelToken);
  axios
    .post(url, opt, {
      cancelToken: cancelToken.token,
      headers: { authorizationToken: `Bearer ${t}` }
    })
    .then(response => {
      onSuccess(response);
    })
    .catch(error => {
      onError(error.response ? error.response : error);
    });
};

export const getTelluricProducts = (opt: any, onSuccess: (r: any) => void, onError: (r: any) => void,) => {
  const t = opt.token;
  axios
    .get(`${TELLURIC_URI}/scenes/productnames/`, {
      headers: { authorizationToken: `Bearer ${t}` }
    })
    .then(response => {
      onSuccess(response);
    })
    .catch(error => {
      onError(error.response ? error.response : error);
    });
}