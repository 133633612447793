// UserStore.ts
import { model, Model, modelAction, prop, types } from 'mobx-keystone';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import jwt_decode from 'jwt-decode';
import { config } from 'config/auth';
import { SCOPES, ARCHIVE_COLLECTIONS } from 'config';
import { getGeofence, onboardUser } from 'services';
import { mobxRootContext } from 'contexts';
import { computed, toJS } from 'mobx';
import { reGenerateGeofence } from 'views/tasking/helpers';


interface Contract {
  id: string;
  name: string;
  archive_configuration: boolean;
  tasking_configuration: boolean;
  full_archive_configuration: any;
}
@model('geodas/User')
export class UserStore extends Model({
  geofence: prop<any>({}),
  scope: prop<string>(''),
  principalId: prop<string>(''),
  onboarded: prop<boolean>(false),
  onboarding: prop<boolean>(false),
  contracts: prop<Contract[] | undefined>(undefined),
  activeContract: prop<Contract | undefined>(undefined),
}) {
  auth0Client: Auth0Client | undefined;
  regeneratedGeofence: number[][] | undefined;

  async onInit() {
    if (!this.auth0Client) {
      this.auth0Client = await this.createClient();
      await this.handleRedirectCallback();
    }
    
    // Restore the active contract from localStorage
    const storedContract = localStorage.getItem('activeContract');
    if (storedContract) {
      try {
        const contract = JSON.parse(storedContract);
        this.setActiveContract(contract); // Set the active contract in the store
      } catch (error) {
        console.error('Error parsing active contract from localStorage:', error);
      }
    }
  }

  @modelAction
  setContracts(contracts: Contract[]) {
    this.contracts = contracts;
  }

  @modelAction
  setPrincipalId(sub: string) {
    this.principalId = sub;
  }

  @modelAction
  setActiveContract(contract: Contract | undefined) {
    this.activeContract = contract;    
  }

  @modelAction
  setDefaultContract(contract: Contract){            
    localStorage.setItem('activeContract', JSON.stringify(contract));      
  }

  @modelAction
  removeDefaultContract(){    
    localStorage.removeItem('activeContract');
  }

  @modelAction
  setScope(scope: string) {
    this.scope = scope;
  }

  @modelAction
  setGeofence(g: any) {
    this.geofence = g;
  }

  @modelAction
  setOnboarded(value: boolean) {
    this.onboarded = value;
  }

  @modelAction
  setOnboarding(value: boolean) {
    this.onboarding = value;
  }

  hasTaskingPermission() {
    if (!this.activeContract) return false
    return this.activeContract.tasking_configuration
  }
  hasArchivePermission() {
    if (!this.activeContract) return false
    if (!this.activeContract.full_archive_configuration) return false
    return this.activeContract.full_archive_configuration.collections.length > 0
  }
  hasGaiaPermission() {
    return this.scope.includes(SCOPES.GAIA)
  }
  hasTaskingV2Permission() {
    return this.scope.includes(SCOPES.TASKING_V2)
  }

  async createClient() {
    const requested_scopes = `openid profile email ${SCOPES.TASKING} ${SCOPES.DELIVERY} ${SCOPES.ALEPH} ${SCOPES.ARCHIVE} ${SCOPES.GAIA} ${SCOPES.TASKING_V2} ${SCOPES.ARCHIVE_RASTERS} ${ARCHIVE_COLLECTIONS.join(' ')}`;
    return await createAuth0Client({
      domain: config.domain,
      clientId: config.clientId,
      authorizationParams: {
        redirect_uri: window.location.href,
        scope: requested_scopes,
        audience: config.audience
      }
    });
  }

  @computed
  get detachedGeofence() {
    return toJS(this.geofence);
  }

  @computed
  get detachedRegeneratedGeofence() {
    return toJS(this.regeneratedGeofence);
  }

  @modelAction
  async isAuthenticated() {
    return await this.auth0Client?.isAuthenticated();
  }

  async handleRedirectCallback() {
    const isAuthenticated = await this.auth0Client?.isAuthenticated();
    if (!isAuthenticated) {
      const query = window.location.search;
      if (query.includes('code=') && query.includes('state=')) {
        try {
          await this.auth0Client?.handleRedirectCallback();
          window.history.replaceState({}, document.title, window.location.pathname);
        } catch (error) {
          console.log("error")
          window.location.href = window.location.origin + window.location.pathname;
        }
        await this.afterLogin();
      } else {
        await this.login();
      }
    } else {
      await this.afterLogin();
    }
  }

  async afterLogin() {
    await this.refreshScopes();
  }

  async onContractSelection() {
    // Load the user's geofence
    if (this.hasTaskingPermission()) {
      await this.loadGeofence();
    }
  }

  async refreshScopes(forceRefresh: boolean = false) {
    const cacheMode = forceRefresh ? 'off' : 'on';
    const token = await this.auth0Client?.getTokenSilently({ cacheMode: cacheMode });
    if (token) {
      const userData: { scope: string, sub: string, contracts: Contract[] } = jwt_decode(token);
      this.setContracts(userData.contracts);
      this.setScope(userData.scope);
      this.setPrincipalId(userData.sub);
      // Already set active contract. Do it here after scope and principal is set
      if (userData.contracts.length == 1) {
        this.setActiveContract(userData.contracts[0])
      }
    } else {
      this.setScope('');
    }
  }

  async login() {
    await this.auth0Client?.loginWithRedirect();
  }

  @modelAction
  async onboard(onFail: Function) {
    if (this.onboarding) {
      return
    } else {
      this.setOnboarding(true)
    }

    try {
      const token = await this.auth0Client?.getTokenSilently();
      await onboardUser(
        { token },
        async (response) => {
          await this.refreshScopes(true); // Force refresh to get updated scopes
          this.setOnboarded(true);
          this.setOnboarding(false);
        },
        (error) => {
          console.error('Onboarding error:', error);
          this.setOnboarded(false);
          this.setOnboarding(false);
          onFail();
        }
      );
    } catch (error) {
      console.error('Onboarding exception:', error);
      this.setOnboarded(false);
    }
  }

  async loadGeofence() {
    const token = await this.auth0Client?.getTokenSilently();
    getGeofence(
      { token },
      ({ data }) => {
        if (data.geofence !== null) {
          let geofence = typeof data.geofence === 'object' ? data.geofence : JSON.parse(data.geofence);

          const newGeofence: number[][][] = [];
          newGeofence.push([
            [180, 90],
            [-180, 90],
            [-180, -90],
            [180, -90]
          ]);
          for (const l in geofence.coordinates) {
            newGeofence.push(geofence.coordinates[l][0]);
          }
          geofence.coordinates = [newGeofence];
          this.setGeofence(geofence);
          const g = reGenerateGeofence(geofence, true);
          g.shift();
          this.regeneratedGeofence = g;
        } else {
          this.setGeofence({});
        }
      },
      () => {
        mobxRootContext
          .get(this)
          ?.notificationStore.add({ description: 'Error while loading geofence' });
      }
    );
  }

  logout() {
    const legacyTenantLogoutUrl = `https://${config.legacyDomain}/v2/logout?client_id=${config.legacyClientId}&returnTo=${encodeURIComponent(window.location.origin)}`
    const baseLogoutURL = `https://${config.domain}/v2/logout?client_id=${config.clientId}`
    let logoutURL: string;
    if (this.principalId.includes('geodas-legacy')) {
      logoutURL = `${baseLogoutURL}&returnTo=${encodeURIComponent(legacyTenantLogoutUrl)}`
    } else {
      logoutURL = `${baseLogoutURL}&returnTo=${encodeURIComponent(window.location.origin)}`
    }
    window.location.href = logoutURL;
  }
}
