import styled from '@emotion/styled/macro';

// Styled components
export const Container = styled.div`
    font-family: Arial, sans-serif;
    margin: 2px;
`;

export const GroupContainer = styled.div`
    margin-bottom: 40px;
`;

export const GroupTitle = styled.h4`
    color: #333;
    font-size: 0.9em;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
`;

export const Title = styled.h2`
    color: #333;
    font-size: 1.1em;
    border-bottom: 2px solid #ddd;
    padding-bottom: 20px;
`;

export const Table = styled.table`
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    table-layout: fixed; /* Ensure table respects the container's width */
    overflow-x: auto; /* Add horizontal scrolling when necessary */
    display: block; /* Allows scroll when content exceeds width */
`;

export const Thead = styled.thead`
    background-color: #f2f2f2;
`;

export const Th = styled.th`
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 0.8em;
`;

export const Tbody = styled.tbody`
    tr:nth-child(even) {
        background-color: #f9f9f9;
    }
`;

export const Td = styled.td`
    padding: 12px;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
    word-wrap: break-word; /* Ensure long words or content break to fit */
    font-size: 0.8em;
`;

export const SpecContainer = styled.div`
    font-size: 0.9em;
    color: #555;
`;

export const SpecItem = styled.div`
    margin-top: 4px;
`;
